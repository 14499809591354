/*global CXPay*/
import React, {useState, useEffect, useMemo} from 'react';
import {useForm} from "react-hook-form";
import {useAlert} from 'react-alert';
import {useQueryParam, StringParam} from 'use-query-params';
import axios from 'axios';
import ClipLoader from "react-spinners/RiseLoader";
import icon_success from './success_icon.svg';
import CountryList from './countryList.js'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import './App.css';


function App() {
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [isEnoughInfo, setIsEnoughInfo] = useState(false);

    const [action_page] = useQueryParam('action_page', StringParam);
    const [billingEmail] = useQueryParam('billingEmail', StringParam);
    const [userId] = useQueryParam('userId', StringParam);
    const [cacheAPI] = useQueryParam('cacheAPI', StringParam);

    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])


    const {
        register,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm();

    const changeHandler = value => {
        setValue(value)
    }
    const onSubmit = (data) => {
        console.log(data);
        setIsLoading(true);

        var cacheBody = {
            sessionId: userId,
            secureID: data.cardCVV
        };
        console.log(cacheBody);
        console.log(cacheAPI);
        axios({
            method: "post",
            url: cacheAPI,
            data: cacheBody,
            headers: {"Content-Type": "application/json"},
        })
            .then(function (response) {
                //handle success
                console.log("success = " + response);
            })
            .catch(function (response) {
                //handle error
                console.log("error = " + response);
            });

        var bodyFormData = new FormData();
        bodyFormData.append('billing-cc-number', data.cardNumber);
        bodyFormData.append('billing-cc-exp', data.cardExpiry);
        bodyFormData.append('billing-cvv', data.cardCVV);
        bodyFormData.append('billing-address1', data.billingAddress);
        bodyFormData.append('billing-city', data.billingCity);
        bodyFormData.append('billing-state', data.billingState);
        bodyFormData.append('billing-postal', data.billingPostal);
        bodyFormData.append('billing-country', data.billingCountry);
        if (!billingEmail) {
            bodyFormData.append('billing-email', data.billingEmail);
        }

        console.log(bodyFormData);
        axios({
            method: "post",
            url: action_page,
            data: bodyFormData,
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(function (response) {
                //handle success
                console.log(response.request);
                window.location.href = response.request.responseURL;
            })
            .catch(function (response) {
                //handle error
                console.log("error = " + response);
            });
    };

    useEffect(async () => {
        if (!action_page) {
            alert.show('Please provide action_page!', {type: 'error'})
            return;
        }
        setIsEnoughInfo(true);
    }, []);

    return (
        <div className="App">
            {isEnoughInfo &&
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <form method='POST' action={action_page} > */}
                    <label>Card number</label>
                    <input
                        {...register("cardNumber", {
                            required: true,
                            minLength: 10,
                            pattern: /^[0-9]+$/i
                        })}
                        type="number"
                    />
                    {errors ?.cardNumber?.type === "required" &&
                        <p>Card Number is required</p>
                    }
                    {errors ?.cardNumber?.type === "minLength" &&
                        <p>Card Number must be at least 10 digits</p>
                    }
                    {errors ?.cardNumber?.type === "pattern" &&
                        <p>Number only</p>
                    }
                    <label>CVV</label>
                    <input {...register("cardCVV",
                        {
                            required: true,
                            pattern: /^[0-9]+$/i,
                            minLength: 3,
                            maxLength: 4
                        })}
                           type="text"
                    />
                    {/* {errors && errors['billing-cvv'] && errors['billing-cvv'].type === "required" &&
                     <p>CVV is required</p>
                     } */}
                    {errors ?.cardCVV?.type === "required" &&
                        <p>CVV is required</p>
                    }
                    {errors ?.cardCVV?.type === "pattern" &&
                        <p>Number only</p>
                    }
                    {(errors ?.cardCVV ?.type === "minLength" || errors?.cardCVV?.type === "maxLength") &&
                        <p>CVV is not valid</p>
                    }
                    <label>Expiration date</label>

                    <input {...register("cardExpiry",
                        {
                            required: true,
                            minLength: 4,
                            maxLength: 5
                        })}
                           type="text"
                           placeholder="MM/YY"
                    />
                    {errors ?.cardExpiry?.type === "required" &&
                        <p>Expiration date is required</p>
                    }
                    {(errors ?.cardExpiry ?.type === "minLength" || errors?.cardExpiry?.type === "maxLength") &&
                        <p>Expiration date is not valid</p>
                    }

                    <label>Billing address</label>
                    <input {...register("billingAddress",
                        {
                            required: true
                        })}
                           type="text"
                    />
                    {errors ?.billingAddress?.type === "required" &&
                        <p>Billing address is required</p>
                    }
                    <label>Billing city</label>
                    <input {...register("billingCity",
                        {
                            required: true
                        })}
                           type="text"
                    />
                    {errors ?.billingCity?.type === "required" &&
                        <p>Billing city is required</p>
                    }
                    <label>Billing state</label>
                    <input {...register("billingState",
                        {
                            required: true
                        })}
                           type="text"
                    />
                    {errors ?.billingState?.type === "required" &&
                        <p>Billing state is required</p>
                    }
                    <label>Billing postal</label>
                    <input {...register("billingPostal",
                        {
                            required: true
                        })}
                           type="text"
                    />
                    {errors ?.billingPostal?.type === "required" &&
                        <p>Postal code is required</p>
                    }
                    <label>Billing country</label>
                    <select {...register("billingCountry")}> {options.map((option) => <option key={option.value}
                                                                                              value={option.value}>{option.label}</option>)} </select>
                    {(() => {
                        if (!billingEmail) {
                            return (
                                <div>
                                    <label>Billing email</label>
                                    <input {...register("billingEmail",
                                        {
                                            required: true,
                                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                        })}
                                           type="text"
                                    />
                                    {errors ?.billingEmail?.type === "required" &&
                                        <p>Email is required</p>
                                    }
                                    {errors ?.billingEmail?.type === "pattern" &&
                                        <p>Please enter an email address</p>
                                    }
                                </div>
                            )
                        }
                        return null;
                    })()}

                    <input type="submit"/>
                </form>
            </div>
            }
            {
                isLoading &&
                <div id="loading">
                    <ClipLoader
                        size={20}
                        color="white"
                    />
                </div>
            }
        </div>
    );
}

export default App;
